import ImageView from "@/components/ImageUpload/ImageView"
import useCustomRouter from "@/hooks/useCustomRouter"
import { cn } from "@/lib/utils"
import { isCheckDomainUrl, isLinkUrlImage } from "@/utils"
import React, { memo, useMemo } from "react"

const SearchResult = () => {
  const router = useCustomRouter()

  const { search } = router.query
  const formatSearch = typeof search === "string" ? search : ""

  const isCheckedInternalImage = useMemo(() => {
    if (typeof search !== "string") return false

    const isCheckDomain = isCheckDomainUrl(search)
    const isCheckImage = isLinkUrlImage(search)

    return isCheckDomain && isCheckImage
  }, [search])

  if (isCheckedInternalImage) return null

  return (
    <div className="mb-4 flex flex-col items-start">
      <h3
        className={cn("md:text-xl font-semibold", {
          hidden: !search,
        })}
      >
        Search results {isCheckedInternalImage ? null : `"${formatSearch}"`}
      </h3>
      {isCheckedInternalImage && (
        <div className="flex items-start mt-1 max-w-[20rem]">
          &quot;
          <ImageView alt={""} className="h-[10rem] w-full" url={formatSearch} />
          &quot;
        </div>
      )}
    </div>
  )
}

export default memo(SearchResult)
