import classNames from "classnames"
import { motion } from "framer-motion"
import React, { Fragment, memo, useEffect, useMemo } from "react"
import InfiniteScroll from "react-infinite-scroller"
import GalleryNotFound from "./GalleryNotFound"
import { ArticleCategory, ArticleStatus, PickTypeClass, SortOptions } from "@/api/sdk"
import ArticleItem from "./ArticleItem"
import GalleryFeaturesAction from "./GalleryFeatures/GalleryFeaturesAction"
import { useExploreArticlesInfiniteQuery } from "@/queries"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useAuth } from "@/providers/authContext"
import LimitUserNotAuth from "@/components/shared/LimitUserNotAuth"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"
import { useDebounce } from "@/hooks"
import ErrorUI from "@/components/ErrorUI"

interface GalleryArticlesProps {
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  selectTags: PickTypeClass[]
  category: string[] | null
  isBookmarked: boolean
  setIsLoadingInside: (value: boolean) => void
}

const GalleryArticles = ({ category, isBookmarked, selectTags, sort, setIsLoadingInside }: GalleryArticlesProps) => {
  const router = useCustomRouter()
  const { search } = router.query
  const { loading } = useAuth()

  const variables = useMemo(() => {
    return {
      category: category as ArticleCategory[],
      tagIds: selectTags.map(tag => tag.id),
      sortBy: sort.sortBy as SortOptions,
      onlyFavorite: isBookmarked,
      searchTerm: search as string,
      statuses: [ArticleStatus.PUBLISHED],
    }
  }, [category, selectTags, sort, isBookmarked, search])

  const debounceVariables = useDebounce(variables, 150)

  const {
    flattenData: mappedArticles,
    fetchNextPage,
    hasNextPage,
    isFetching,
    length,
    isLoading,
    isError,
  } = useExploreArticlesInfiniteQuery({
    variables: debounceVariables,
    enabled: !loading,
  })

  useEffect(() => {
    setIsLoadingInside(isLoading)
  }, [isLoading])

  const renderBody = () => {
    if (isLoading) return <div className="flex items-center justify-center flex-1 w-full text-gray-600"></div>

    if (isError) return <ErrorUI />

    if (!mappedArticles || mappedArticles.length === 0)
      return (
        <div className="flex flex-col flex-1 relative">
          <GridBreakpointColsContainer className="absolute top-0 left-0 z-[1]">
            <GalleryFeaturesAction />
          </GridBreakpointColsContainer>
          <GalleryNotFound text="No items" />
        </div>
      )
    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        useWindow={true}
        threshold={800}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35, delay: 0.35 }}
          className={classNames("relative")}
        >
          <GridBreakpointColsContainer>
            <GalleryFeaturesAction />
            {mappedArticles?.map(article => <ArticleItem hiddenMenu key={article.id} data={article} />)}
          </GridBreakpointColsContainer>
        </motion.div>
      </InfiniteScroll>
    )
  }

  return (
    <Fragment>
      {renderBody()}
      {length >= 100 && <LimitUserNotAuth />}
    </Fragment>
  )
}

export default memo(GalleryArticles)
