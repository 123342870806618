import { ContentModerationRating, ExploreSDWorkflowItem } from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { cn } from "@/lib/utils"
import { RoutedDialogLink } from "@/providers/RoutedDialogProvider"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useInView } from "react-intersection-observer"
import UserCreated from "../UserCreated"
import ComfyUIReaction from "./ComfyUIReaction"
import { useState } from "react"
import classNames from "classnames"
import NsfwBlurImage from "@/components/NsfwBlurImage"

interface RecipeCardProps {
  workflow: ExploreSDWorkflowItem
  href?: string
  className?: string
  typeRedirectRouter?: "push" | "replace"
  hiddenReaction?: boolean
}

const ComfyUIItemCard = ({ workflow, href, className, typeRedirectRouter, hiddenReaction }: RecipeCardProps) => {
  const { thumbnailUrl, name, id, description, thumbnailModerationRating } = workflow
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })
  const router = useCustomRouter()
  const historyPath = router.asPath
  const [isShow, setIsShow] = useState(
    thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
      thumbnailModerationRating === ContentModerationRating.SemiNsfw,
  )

  return (
    <div ref={ref} className={cn("group h-full cursor-pointer shrink relative recipe-item", className)}>
      <div className="w-full h-full flex flex-col">
        <RoutedDialogLink
          className="flex flex-col flex-1"
          name="comfyuiDetailExplore"
          type={typeRedirectRouter}
          state={{
            sdWorkflowId: workflow.id,
            filters: {
              historyPath,
            },
          }}
        >
          <div className="relative group overflow-hidden">
            <AspectRatio
              ratio={255 / 120}
              className={
                "relative select-none overflow-hidden rounded-xl transform-gpu flex items-center justify-center shadow-md shadow-blackAlpha-500"
              }
            >
              {inView && (
                <ImageWebpComponent
                  src={thumbnailUrl}
                  alt={name}
                  className={classNames("block object-cover w-full h-full", {
                    "blur-2xl": isShow,
                  })}
                />
              )}
            </AspectRatio>

            {inView &&
              (thumbnailModerationRating === ContentModerationRating.HardcoreNsfw ||
                thumbnailModerationRating === ContentModerationRating.SemiNsfw) && (
                <NsfwBlurImage
                  isShow={isShow}
                  moderationRating={thumbnailModerationRating}
                  onToggleShow={setIsShow}
                  gaEvent={{
                    tab_name: "comfyui",
                    params: {
                      action: "Toggle Image NSFW",
                      comfyui_id: workflow.id,
                      comfyui_name: workflow.name ?? "",
                    },
                  }}
                />
              )}

            {!hiddenReaction && inView && (
              <ComfyUIReaction showHoverAttributes className="p-2 z-[1]" workflow={workflow} />
            )}
          </div>
          <div className="p-2 flex flex-col flex-1 overflow-hidden">
            <p className="font-semibold line-clamp-1 text-sm">{name}</p>
            <p className="text-atherGray-300 text-sm line-clamp-1">{description}</p>
          </div>
        </RoutedDialogLink>
        <div className="mt-auto px-2">
          <UserCreated
            username={workflow.creator.username}
            size="xs"
            name={workflow.creator?.name}
            picture={workflow.creator?.picture}
          />
        </div>

        {inView && (
          <div className="absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full" />
        )}
      </div>
    </div>
  )
}

export default ComfyUIItemCard
