import { ExploreImageSimpleDetail, ImageSimpleDetail } from "@/api/sdk"
import { createContext, useContext } from "react"

export type ImagesContextState = {
  images?: ExploreImageSimpleDetail[] | ImageSimpleDetail[]
}

const ImagesContext = createContext<ImagesContextState | null>(null)
export const useImagesContext = () => {
  const context = useContext(ImagesContext)
  if (!context) throw new Error("missing ImagesContext")
  return context
}

export function ImagesProvider({
  children,
  ...state
}: {
  children: React.ReactNode
} & ImagesContextState) {
  const { images } = state

  return (
    <ImagesContext.Provider
      value={{
        images,
      }}
    >
      {children}
    </ImagesContext.Provider>
  )
}
