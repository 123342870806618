import { PickTypeClass, SDStyleDetail, SortOptions } from "@/api/sdk"
import StyleItem from "@/components/Tools/Style/StyleItem"
import { useAuth } from "@/providers/authContext"
import { useExploreStylesInfiniteQuery } from "@/queries"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, memo, useEffect, useMemo } from "react"
import InfiniteScroll from "react-infinite-scroller"
import Masonry from "@/components/Masonry"
import GalleryNotFound from "./GalleryNotFound"
import LimitUserNotAuth from "@/components/shared/LimitUserNotAuth"
import { getExploreBreakpointCols } from "@/utils/grid-cols"
import { useDebounce } from "@/hooks"
import ErrorUI from "@/components/ErrorUI"

interface GalleryStylesProps {
  containerWidth: number
  createdBy: string | null
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  selectTags: PickTypeClass[]
  category: string[] | null
  setIsLoadingInside: (value: boolean) => void
}
const GalleryStyles = ({
  containerWidth,
  sort,
  selectTags,
  category,
  setIsLoadingInside,
  createdBy,
}: GalleryStylesProps) => {
  const router = useCustomRouter()
  const { search } = router.query
  const { user, loading } = useAuth()

  const variables = useMemo(() => {
    return {
      creatorUid: createdBy === "Me" ? user?.uid : undefined,
      onlyFavorite: category?.includes("My favorites"),
      onlyCurrentWorkspace: createdBy === "Workspace",
      sortBy: sort.sortBy as SortOptions,
      tagIds: selectTags.map(i => i.id),
      searchTerm: search as string,
    }
  }, [category, selectTags, sort, search, user, createdBy])

  const debounceVariables = useDebounce(variables, 150)

  const {
    flattenData: mappedSyles,
    fetchNextPage,
    hasNextPage,
    isFetching,
    length,
    isLoading,
    isError,
  } = useExploreStylesInfiniteQuery({
    variables: debounceVariables,
    enabled: !loading,
  })

  useEffect(() => {
    setIsLoadingInside(isLoading)
  }, [isLoading])

  const renderBody = () => {
    if (isLoading) return <div className="flex items-center justify-center w-full flex-1 text-gray-600"></div>

    if (isError) return <ErrorUI />

    if (!mappedSyles || mappedSyles.length === 0) return <GalleryNotFound text="No items" />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={classNames("relative")}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={true}
          threshold={800}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            width: "100%",
            position: "relative",
          }}
          loader={
            <div key="loader" className="flex items-center justify-center py-4">
              Loading...
            </div>
          }
        >
          <Masonry
            breakpointCols={{
              default: getExploreBreakpointCols(containerWidth),
            }}
            className="flex w-full gap-4"
            columnClassName="gap-4 flex flex-col"
            id="workflow-list"
          >
            {mappedSyles?.map(style => {
              return (
                <StyleItem
                  key={style.id}
                  name="styleDetailExplore"
                  hideActions
                  style={style as SDStyleDetail}
                  containerWidth={containerWidth}
                />
              )
            })}
          </Masonry>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <Fragment>
      {renderBody()}
      {length >= 100 && <LimitUserNotAuth />}
    </Fragment>
  )
}

export default memo(GalleryStyles)
