import { PickTypeClass, SortOptions } from "@/api/sdk"
import { ImagesProvider } from "@/providers/ImagesProvider"
import { useAuth } from "@/providers/authContext"
import { useExploreImagesInfiniteQuery } from "@/queries"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useEffect, useMemo } from "react"
import InfiniteScroll from "react-infinite-scroller"
import GalleryNotFound from "./GalleryNotFound"
import ImageCardNavigator from "./ImageItem/ImageCardNavigator"
import LimitUserNotAuth from "@/components/shared/LimitUserNotAuth"
import Masonry from "@/components/Masonry"
import { getExploreBreakpointCols } from "@/utils/grid-cols"
import { useDebounce } from "@/hooks"
import ErrorUI from "@/components/ErrorUI"

interface GalleryListProps {
  containerWidth: number
  createdBy: string | null
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  selectTags: PickTypeClass[]
  category: string[] | null
  setIsLoadingInside: (value: boolean) => void
}
const GalleryImages = ({
  containerWidth,
  category,
  selectTags,
  sort,
  setIsLoadingInside,
  createdBy,
}: GalleryListProps) => {
  const router = useCustomRouter()
  const { search } = router.query
  const { loading } = useAuth()

  const variables = useMemo(() => {
    return {
      onlyMine: createdBy === "Me",
      onlyFavorite: createdBy === "My favorites",
      sortBy: sort.sortBy as SortOptions,
      tagIds: selectTags.map(i => i.id),
      searchTerm: search as string,
      onlyCurrentWorkspace: createdBy === "Workspace",
    }
  }, [createdBy, selectTags, sort, search])

  const debounceVariables = useDebounce(variables, 150)

  const {
    flattenData: mappedImages,
    fetchNextPage,
    hasNextPage,
    length,
    isFetching,
    isLoading,
    isError,
  } = useExploreImagesInfiniteQuery({
    variables: debounceVariables,
    enabled: !loading,
  })

  useEffect(() => {
    setIsLoadingInside(isLoading)
  }, [isLoading])

  const renderBody = () => {
    if (isLoading) return <div className="flex items-center justify-center flex-1 w-full text-gray-600"></div>

    if (isError) return <ErrorUI />

    if (!mappedImages || mappedImages.length === 0) return <GalleryNotFound text="No items" />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={classNames("relative pb-16 md:pb-0")}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={true}
          threshold={1000}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            width: "100%",
            position: "relative",
          }}
          loader={
            <div key="loader" className="flex items-center justify-center py-4">
              Loading...
            </div>
          }
        >
          <Masonry
            breakpointCols={{
              default: getExploreBreakpointCols(containerWidth),
            }}
            className="flex w-full gap-1 md:gap-2 lg:gap-4"
            columnClassName="gap-1 md:gap-2 lg:gap-4 flex flex-col"
            id="images-list"
          >
            {mappedImages?.map(image => {
              return (
                <ImageCardNavigator
                  isExplore
                  hiddenMenu
                  hiddenSelect
                  filters={{
                    ...debounceVariables,
                    promptMode: true,
                  }}
                  containerWidth={containerWidth}
                  key={image.id}
                  data={image}
                />
              )
            })}
          </Masonry>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <ImagesProvider images={mappedImages}>
      {renderBody()}
      {length >= 100 && <LimitUserNotAuth />}
    </ImagesProvider>
  )
}

export default memo(GalleryImages)
