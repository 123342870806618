import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useRef, useState } from "react"

import GalleryHeader from "./GalleryHeader"
import useContainerWidth from "@/hooks/useContainerWidth"
import { PickTypeClass } from "@/api/sdk"
import { googleAnalytics } from "@/lib/gtag"
import GalleryInspirationBody from "./GalleryInspirationBody"

export type ExploreGalleryTabType = "images" | "articles" | "macros" | "creators" | "styles" | "recipes" | "comfyui"

interface GalleryInspirationProps {
  type: ExploreGalleryTabType
}

const GalleryInspiration = ({ type }: GalleryInspirationProps) => {
  const [tab, setTab] = useState(type)
  const router = useCustomRouter()
  const scrollRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerWidth = useContainerWidth(containerRef, true)

  useEffect(() => {
    if (type && router.isReady) {
      setTab(type)
    }
  }, [type, router.isReady])

  return (
    <div className={"relative flex flex-col w-full flex-1 pb-[4rem] md:pb-0"}>
      <div id="gallery-images" className={"flex flex-col w-full flex-1"}>
        <div ref={scrollRef} className={"relative flex flex-row w-full mx-auto justify-center flex-1"}>
          <div
            ref={containerRef}
            className="flex flex-col relative w-full flex-1 h-full items-center max-w-[85rem] 2xl:max-w-[105rem] 3xl:max-w-[135rem] 5xl:max-w-[155rem]"
          >
            <GalleryInspirationBody type={tab} containerWidth={containerWidth} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GalleryInspiration
