import { ExploreRecipeItem } from "@/api/sdk"
import classNames from "classnames"

import IconButton from "@/components/IconButton"
import { FavoriteFillIcon, FavoriteIcon } from "@/components/shared/icons/"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { useSignInStore } from "@/stores"
import { twMerge } from "tailwind-merge"
import UserCreated from "../UserCreated"
import { useFavoriteRecipeMutation } from "@/queries/workspace/recipe"
import { useFavoriteComfyUIMutation } from "@/queries/tools/comfyui-recipe"

interface ComfyUIReactionProps {
  workflow: {
    id: string
    favoritedByUser: boolean
    metric: {
      favoriteCount: number
    }
  }
  className?: string
  style?: React.CSSProperties
  showHoverAttributes?: boolean
  hiddenReaction?: boolean
  refetchImages?: () => void
}

const ComfyUIReaction = ({
  workflow,
  refetchImages,
  className,
  showHoverAttributes,
  hiddenReaction,
}: ComfyUIReactionProps) => {
  const { user } = useAuth()
  const setSignInModal = useSignInStore(state => state.setSignInModal)

  const { mutate: mutateReaction } = useFavoriteComfyUIMutation({
    onSuccess: () => {
      refetchImages?.()
    },
  })

  const handleReaction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    if (!user) {
      setSignInModal({
        signIn: true,
      })
      return
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Favorite comfyUI",
        tab_name: "comfy_ui",
        comfy_ui_id: workflow.id,
      },
    })

    mutateReaction({
      id: workflow.id,
      userUid: user.uid,
    })
  }

  if (!showHoverAttributes)
    return (
      <IconButton
        onClick={e => {
          handleReaction(e)
        }}
        className={twMerge("flex items-center space-x-1 p-1 text-sm bg-transparent", className)}
        colorScheme="secondary"
      >
        {!workflow.favoritedByUser ? (
          <FavoriteIcon className="text-atherGray-300" />
        ) : (
          <FavoriteFillIcon className="text-atherGray-300" />
        )}
        <p className="text-atherGray-300">{Math.max(workflow.metric?.favoriteCount || 0, 0)}</p>
      </IconButton>
    )

  return (
    <div>
      {!hiddenReaction && (
        <>
          <div
            className="h-[3.25rem] absolute bottom-0 left-0 w-full pointer-events-none"
            style={{
              background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.7) 100%)",
            }}
          />
          <IconButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()

              handleReaction(e)
            }}
            className={twMerge("flex absolute bottom-2 left-2 items-center p-1 text-sm bg-transparent", className)}
            colorScheme="transparent"
          >
            {!workflow.favoritedByUser ? (
              <FavoriteIcon className="text-atherGray-300" />
            ) : (
              <FavoriteFillIcon className="text-atherGray-300" />
            )}
            <p className="text-atherGray-300">{Math.max(workflow.metric?.favoriteCount || 0, 0)}</p>
          </IconButton>
        </>
      )}
    </div>
  )
}

export default ComfyUIReaction
