import { FC, useState } from "react"
import GalleryArticles from "./GalleryArticles"
import GalleryImages from "./GalleryImages"
import GalleryWorkflows from "./GalleryWorkflows"
import GalleryCreator from "./GalleryCreator"
import GalleryStyles from "./GalleryStyles"
import GalleryRecipes from "./GalleryRecipes"
import GalleryComfyUI from "./GalleryComfyUI"
import GalleryFooter from "./GalleryFooter"
import { useAuth } from "@/providers/authContext"
import GalleryGridLoader from "./GalleryGridLoader"
import { AnimatePresence, motion } from "framer-motion"
import useLoaded from "@/hooks/useLoaded"
import classNames from "classnames"
import SearchResult from "./SearchResult"
import { useExploreContext } from "@/providers/ExploreProvider"

export type ExploreGalleryTabType = "images" | "articles" | "macros" | "creators" | "styles" | "recipes" | "comfyui"

type GalleryInspirationBodyProps = {
  type: ExploreGalleryTabType
  containerWidth: number
}

const GalleryInspirationBody: FC<GalleryInspirationBodyProps> = props => {
  const { type: tab, containerWidth } = props
  const { loading } = useAuth()
  const { loaded } = useLoaded()
  const { category, isBookmarked, selectTags, sort, createdBy } = useExploreContext()
  const [isLoadingInside, setIsLoadingInside] = useState(true)

  const renderGalleryItems = () => {
    switch (tab) {
      case "articles":
        return (
          <GalleryArticles
            setIsLoadingInside={setIsLoadingInside}
            selectTags={selectTags}
            category={category}
            sort={sort}
            isBookmarked={isBookmarked}
          />
        )
      case "macros":
        return (
          <GalleryWorkflows
            setIsLoadingInside={setIsLoadingInside}
            createdBy={createdBy}
            containerWidth={containerWidth}
            selectTags={selectTags}
            category={category}
            sort={sort}
          />
        )
      case "creators":
        return <GalleryCreator setIsLoadingInside={setIsLoadingInside} sort={sort} />
      case "styles":
        return (
          <GalleryStyles
            setIsLoadingInside={setIsLoadingInside}
            createdBy={createdBy}
            containerWidth={containerWidth}
            selectTags={selectTags}
            category={category}
            sort={sort}
          />
        )
      case "recipes":
        return (
          <GalleryRecipes
            setIsLoadingInside={setIsLoadingInside}
            createdBy={createdBy}
            containerWidth={containerWidth}
            selectTags={selectTags}
            category={category}
            sort={sort}
          />
        )

      case "comfyui":
        return (
          <GalleryComfyUI
            setIsLoadingInside={setIsLoadingInside}
            createdBy={createdBy}
            containerWidth={containerWidth}
            selectTags={selectTags}
            category={category}
            sort={sort}
          />
        )

      default:
        return (
          <GalleryImages
            setIsLoadingInside={setIsLoadingInside}
            containerWidth={containerWidth}
            createdBy={createdBy}
            selectTags={selectTags}
            category={category}
            sort={sort}
          />
        )
    }
  }

  if (containerWidth === 0) return null

  return (
    <>
      <div className="flex flex-col flex-1 w-full px-4 pb-8 md:px-12 ">
        <SearchResult />
        <div className="relative flex flex-col flex-1">
          {renderGalleryItems()}
          <AnimatePresence initial={false} mode="wait">
            {(loading || !loaded || isLoadingInside) && (
              <motion.div
                key="loading"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.35 } }}
                exit={{ opacity: 0, transition: { duration: 0.25, delay: 0.5 } }}
                className={classNames("w-full absolute left-0 h-full top-0 z-[2] bg-black")}
              >
                <GalleryGridLoader />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <GalleryFooter tab={tab} />
    </>
  )
}

export default GalleryInspirationBody
