import { cn } from "@/lib/utils"
import React, { FC } from "react"

import styles from "./GalleryGridLoader.module.scss"

const GalleryGridLoader: FC = () => {
  return (
    <>
      <div className="flex gap-4 justify-center w-full overflow-hidden">
        <div className="flex flex-col gap-2 md:gap-4 w-full max-w-[400px]">
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "1/1" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "16/9" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "2/3" }} />
        </div>
        <div className="flex flex-col gap-4 w-full max-w-[400px]">
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "4/3" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "1/1" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "2/3" }} />
        </div>
        <div className="flex-col gap-4 w-full max-w-[400px] hidden md:flex">
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "9/12" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "1/1" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "3/2" }} />
        </div>
        <div className="flex-col gap-4 w-full max-w-[400px] hidden xl:flex">
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "4/3" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "1/1" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "16/9" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "1/1" }} />
        </div>
        <div className="flex-col gap-4 w-full max-w-[400px] hidden 3xl:flex">
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "1/1" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "16/9" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "2/3" }} />
        </div>
        <div className="flex-col gap-4 w-full max-w-[400px] hidden 4xl:flex">
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "4/3" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "1/1" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "2/3" }} />
        </div>
        <div className="flex-col gap-4 w-full max-w-[400px] hidden 5xl:flex">
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "9/12" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "1/1" }} />
          <div className={cn("bg-whiteAlpha-300 rounded-3xl", styles["skeleton"])} style={{ aspectRatio: "3/2" }} />
        </div>
      </div>
    </>
  )
}

export default GalleryGridLoader
