import { cn } from "@/utils/cn"
import { ContentModerationRating, ImageSimpleDetail } from "@/api/sdk"
import { memo, useRef } from "react"
import ImageInView from "./ImageInView"
import ImageItemList from "@/components/Workspace/RecentV2/RecentImages/ImageItemList"
import { ImageGenerationSource } from "@/api/sdk"
import { RoutedDialogLink } from "@/providers/RoutedDialogProvider"
import { useImagesContext } from "@/providers/ImagesProvider"
import { GalleryItemProps } from "./ImageItem"
import useCustomRouter from "@/hooks/useCustomRouter"
import ImageReaction from "./ImageReaction"

const ImageCardNavigator = ({
  data: image,
  onSelect,
  containerWidth,
  refetchImages,
  className,
  isDisabledInView,
  hiddenSelectPrompt,
  hiddenMenu,
  showHoverAttributes,
  isExplore,
  selectionMode,
  isChecked,
  onCheck,
  parentFolderId,
  filters,
  isUpload,
  accessedAt,
  stretch,
  classNameItem,
  imgClassName,
  hiddenSelect,
  hiddenReaction,
  layout = "grid",
  typeRedirectRouter,
}: GalleryItemProps) => {
  const galleryItemRef = useRef<HTMLDivElement>(null)
  const { images } = useImagesContext()

  const router = useCustomRouter()

  const historyPath = router.asPath

  return (
    <div
      key={image.id}
      data-id={image.id}
      className={cn("group shrink relative gallery-item", className)}
      ref={galleryItemRef}
    >
      <RoutedDialogLink
        name="imageDetailExplore"
        type={typeRedirectRouter}
        state={{
          imageId: image.id,
          images: images as ImageSimpleDetail[],
          filters: {
            historyPath,
            ...filters,
          },
        }}
      >
        {layout === "grid" ? (
          <ImageInView
            isExplore={isExplore}
            className={classNameItem}
            imgClassName={imgClassName}
            hiddenSelectPrompt={hiddenSelectPrompt}
            parentFolderId={parentFolderId}
            refetchImages={refetchImages}
            isUpload={isUpload}
            hiddenSelect={hiddenSelect}
            containerWidth={containerWidth}
            selectionMode={selectionMode}
            isChecked={isChecked}
            onCheck={onCheck}
            onClick={onSelect}
            hiddenMenu={hiddenMenu}
            isDisabledInView={isDisabledInView}
            showHoverAttributes={showHoverAttributes}
            image={{
              ...image,
              generationSource: ImageGenerationSource.None,
            }}
            hiddenTypeImage={true}
            hiddenReaction={hiddenReaction}
            stretch={stretch}
          />
        ) : (
          <ImageItemList
            image={image as ImageSimpleDetail}
            refetchImages={refetchImages}
            onClick={onSelect}
            accessedAt={accessedAt}
          />
        )}
      </RoutedDialogLink>
      {layout === "grid" && (!hiddenReaction || !hiddenSelectPrompt) && !selectionMode && (
        <ImageReaction
          refetchImages={refetchImages}
          showHoverAttributes={showHoverAttributes}
          hiddenSelectPrompt={hiddenSelectPrompt}
          hiddenReaction={hiddenReaction}
          hiddenName={true}
          className="md:p-2"
          parentFolderId={parentFolderId}
          image={image}
        />
      )}
    </div>
  )
}

export default memo(ImageCardNavigator)
