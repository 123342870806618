import { ArrowUpDoubleIcon } from "@/components/shared/icons/"
import { cn } from "@/lib/utils"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"

const ScrollToTopButton = ({ className }: { className?: string }) => {
  const [isVisible, setIsVisible] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)

    return () => window.removeEventListener("scroll", toggleVisibility)
  }, [])

  return (
    <AnimatePresence mode="wait">
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          onClick={scrollToTop}
          className={cn(
            "hidden md:block fixed top-[5.25rem] rounded-xl md:top-auto bottom-auto md:bottom-8 right-8 z-[1] cursor-pointer bg-atherPurple-500 p-2",
            className,
          )}
        >
          <ArrowUpDoubleIcon width={16} height={16} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ScrollToTopButton
