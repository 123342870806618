import { CreatorProfileItem } from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import IconButton from "@/components/IconButton"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { MetricProfileValue } from "@/components/Profile/ProfileUser"
import { DownloadIcon1, FavoriteIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useMutateFollowUser } from "@/queries"
import classNames from "classnames"
import Link from "next/link"
import { memo } from "react"
import { useInView } from "react-intersection-observer"
import { twMerge } from "tailwind-merge"
import UserCreated from "../UserCreated"

export interface GalleryItemProps {
  data: CreatorProfileItem
  className?: string
}

const ProfileItem = ({ data: profile, className }: GalleryItemProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })
  const { user, handleSignIn } = useAuth()

  const { mutate: mutataFollow, isPending: isFollowing } = useMutateFollowUser()

  return (
    <div
      key={profile.uid}
      className={twMerge(classNames("group cursor-pointer shrink relative gallery-item h-full"), className)}
      ref={ref}
      style={{
        transform: "none",
      }}
    >
      <Link
        onClick={e => {
          e.stopPropagation()

          googleAnalytics.handleCategoryEvent({
            action: "click",
            params: {
              action: "View profile",
              tab_name: "creators",
              profile_username: profile.username,
              profile_id: profile.uid,
            },
          })
        }}
        href={`/profile/${profile.username}`}
        shallow
        className="relative overflow-hidden rounded-xl transform-gpu bg-atherGray-900 shadow-sm shadow-whiteAlpha-200 flex flex-col h-full w-full"
      >
        <AspectRatio ratio={32 / 9} className="relative">
          <div className="relative w-full h-full overflow-hidden">
            <div className="absolute w-full top-0" style={{ top: `${Number(profile.coverImageY)}%` }}>
              {inView && (
                <ImageWebpComponent src={profile.coverImageUrl ?? ""} alt="" className="object-cover w-full block" />
              )}
            </div>
          </div>
          {inView && (
            <div className="absolute p-1 bg-atherGray-950 left-1/2 -translate-x-1/2 -translate-y-1/2 top-full rounded-full overflow-hidden w-24 h-24">
              <UserCreated
                picture={profile.picture}
                name={profile.name}
                hiddenName
                className="w-full h-full"
                imageClassName="w-full h-full"
              />
            </div>
          )}
        </AspectRatio>
        <div className="p-4 mt-10">
          <div className="flex flex-col items-center justify-center w-full mb-2">
            <p className="text-lg font-bold truncate">{profile.name}</p>
            <p className="text-xs text-atherGray-300">
              {profile.metric!.followerCount} {profile.metric!.followerCount > 1 ? "Followers" : "Follower"}
            </p>
          </div>

          <div className="flex items-center justify-center w-full">
            <div className="flex w-[50%] items-center">
              {profile?.metric!.favoriteCount !== undefined && (
                <MetricProfileValue
                  value={profile?.metric.favoriteCount}
                  icon={<FavoriteIcon width={16} height={16} className="text-atherGray-300" />}
                  className="flex-auto text-atherPrimary-100 md:text-base"
                />
              )}
              <div className="bg-atherGray-800 h-[1rem] w-[1px]" />
              {profile?.metric!.downloadCount !== undefined && (
                <MetricProfileValue
                  value={profile?.metric.downloadCount}
                  icon={<DownloadIcon1 width={16} height={16} className="text-atherGray-300" />}
                  className="flex-auto text-atherPrimary-100 md:text-base"
                />
              )}
            </div>
          </div>
          <div className="mt-2">
            {profile.uid !== user?.uid && (
              <IconButton
                isLoading={isFollowing}
                disabled={profile.uid === user?.uid}
                onClick={async e => {
                  e.stopPropagation()
                  e.preventDefault()

                  const isSignIn = await handleSignIn()

                  if (!isSignIn) return

                  googleAnalytics.handleCategoryEvent({
                    action: "click",
                    params: {
                      action: profile.isFollowed ? "Unfollow Profile" : "Follow Profile",
                      tab_name: "creators",
                      profile_username: profile.username,
                      profile_id: profile.uid,
                    },
                  })

                  mutataFollow({ data: { followingUid: profile.uid }, userUid: user?.uid ?? "" })
                }}
                className="w-full py-2"
                colorScheme={!profile.isFollowed ? "primary" : "secondary"}
              >
                {profile.isFollowed ? "Following" : "Follow"}
              </IconButton>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default memo(
  ProfileItem,
  ({ ...prevProps }, { ...nextProps }) => JSON.stringify({ prevProps }) === JSON.stringify(nextProps),
)
