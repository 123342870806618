import { CreatorProfileSortOptions } from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import { useAuth } from "@/providers/authContext"
import { useExploreProfilesInfiniteQuery } from "@/queries"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, memo, useEffect, useMemo } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { SortOptions } from "../../../api/sdk"
import GalleryNotFound from "./GalleryNotFound"
import ProfileItem from "./ProfileItem"
import { useDebounce } from "@/hooks"
import ErrorUI from "@/components/ErrorUI"

interface GalleryListProps {
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  setIsLoadingInside: (value: boolean) => void
}
const GalleryCreator = ({ sort, setIsLoadingInside }: GalleryListProps) => {
  const router = useCustomRouter()
  const { search } = router.query
  const { loading } = useAuth()

  const convertSortBy = (sort: string | undefined) => {
    switch (sort) {
      case SortOptions.MOSTFAVORITE:
        return CreatorProfileSortOptions.MOST_FAVORITED
      case SortOptions.AZ:
        return CreatorProfileSortOptions.A_TO_Z
      case SortOptions.NEWEST:
        return CreatorProfileSortOptions.NEWEST
      default:
        return undefined
    }
  }

  const variables = useMemo(() => {
    return {
      sortBy: convertSortBy(sort.sortBy),
      searchTerm: search as string,
    }
  }, [sort, search])

  const debounceVariables = useDebounce(variables, 150)

  const {
    flattenData: mappedProfiles,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isError,
  } = useExploreProfilesInfiniteQuery({
    variables: debounceVariables,
    enabled: !loading,
  })

  useEffect(() => {
    setIsLoadingInside(isLoading)
  }, [isLoading])

  const renderBody = () => {
    if (isLoading) return <div className="flex items-center justify-center flex-1 w-full text-gray-600"></div>

    if (isError) return <ErrorUI />

    if (!mappedProfiles || mappedProfiles.length === 0) return <GalleryNotFound text="No items" />

    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        useWindow={true}
        threshold={800}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35, delay: 0.35 }}
          className={classNames("relative")}
        >
          <div className="grid gap-4 grid-cols-[repeat(auto-fill,minmax(20rem,1fr))] w-full" id="profile-list">
            {mappedProfiles?.map(profile => {
              return (
                <div key={profile.uid} className="h-full">
                  <ProfileItem data={profile} className={""} />
                </div>
              )
            })}
          </div>
        </motion.div>
      </InfiniteScroll>
    )
  }

  return <Fragment>{renderBody()}</Fragment>
}

export default memo(GalleryCreator)
