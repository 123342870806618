const getExploreBreakpointCols = (containerWidth: number) => {
  if (containerWidth <= 640) {
    return 2
  }
  if (containerWidth <= 1024) {
    return 3
  }

  return Math.ceil(containerWidth / 400)
}

const getWorkspaceBreakpointCols = (containerWidth: number) => {
  if (containerWidth <= 640) {
    return 2
  }
  if (containerWidth <= 1024) {
    return 3
  }

  return Math.ceil(containerWidth / 320)
}

export { getExploreBreakpointCols, getWorkspaceBreakpointCols }
