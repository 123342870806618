import useClientStore from "@/lib/clientStore"
import { useDrawerNotification } from "@/stores"
import classNames from "classnames"
import { motion } from "framer-motion"
import GalleryGeneratedImage from "./GalleryHeader/GalleryGeneratedImage"
import { ExploreGalleryTabType } from "./GalleryInspiration"
import { useAuth } from "@/providers/authContext"
import { Fragment, useState } from "react"
import IconButton from "@/components/IconButton"
import { StarsIcon } from "@/components/shared/icons"
import { isMobile } from "react-device-detect"
import ScrollToTopButton from "./ScrollToTopButton"

const GalleryFooter = ({ tab }: { tab: ExploreGalleryTabType }) => {
  const isMenuOpen = useClientStore(state => state.isMenuOpen)
  const [isOpen, setIsOpen] = useState(true)
  const isOpenNotification = useDrawerNotification(state => state.isOpen)
  const { user } = useAuth()

  const isHidden = !isOpen

  return (
    <Fragment>
      {tab !== "images" || !user ? null : (
        <>
          <motion.div
            animate={{ opacity: isHidden ? 0 : 1, zIndex: isHidden ? -1 : 26 }}
            className={classNames(
              "w-full md:max-w-[calc(100%-10rem)] xl:max-w-[75rem] 2xl:max-w-[85rem] 3xl:max-w-[110rem] 5xl:max-w-[120rem] transition-transform ease-in-out items-center duration-300 flex px-2 flex-col z-[26] fixed bottom-[4.5rem] md:bottom-[1rem] left-1/2 -translate-x-1/2",
              {
                hidden: isMobile && (isMenuOpen || isOpenNotification),
              },
            )}
          >
            <div className="w-full">
              <motion.div
                initial={{ y: 25, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 25, opacity: 0 }}
                className="rounded-2xl bg-atherGray-900 border-[2px] border-atherGray-800"
                style={{
                  backdropFilter: "blur(10px)",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
                transition={{
                  duration: 0.35,
                  ease: "easeInOut",
                }}
              >
                <GalleryGeneratedImage
                  className="bg-atherGray-900 rounded-2xl"
                  tab={tab}
                  onHidden={() => setIsOpen(false)}
                  isMobile={isMobile}
                />
              </motion.div>
            </div>
          </motion.div>
          {isHidden && (
            <IconButton
              onClick={() => setIsOpen(prev => !prev)}
              className="fixed hidden md:block bottom-[4.75rem] right-8 p-2 rounded-xl"
            >
              <StarsIcon width={16} height={16} />
            </IconButton>
          )}
        </>
      )}

      <ScrollToTopButton className="block top-auto bottom-[8rem] right-8" />
    </Fragment>
  )
}

export default GalleryFooter
