import { PickTypeClass, RecipeTaskStatus, SortOptions } from "@/api/sdk"
import WorkflowItem from "@/components/Workspace/Workflows/WorkflowItem"
import { RoutedDialogLink } from "@/providers/RoutedDialogProvider"
import { useAuth } from "@/providers/authContext"
import { useExploreWorkflowsInfiniteQuery } from "@/queries"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, memo, useEffect, useMemo } from "react"
import InfiniteScroll from "react-infinite-scroller"
import Masonry from "@/components/Masonry"
import GalleryNotFound from "./GalleryNotFound"
import UserCreated from "./UserCreated"
import LimitUserNotAuth from "@/components/shared/LimitUserNotAuth"
import { getExploreBreakpointCols } from "@/utils/grid-cols"
import { useDebounce } from "@/hooks"
import ErrorUI from "@/components/ErrorUI"

interface GalleryListProps {
  containerWidth: number
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  selectTags: PickTypeClass[]
  category: string[] | null
  createdBy: string | null
  setIsLoadingInside: (value: boolean) => void
}
const GalleryWorkflows = ({
  containerWidth,
  sort,
  selectTags,
  category,
  setIsLoadingInside,
  createdBy,
}: GalleryListProps) => {
  const router = useCustomRouter()
  const { search } = router.query
  const { user, loading } = useAuth()

  const variables = useMemo(() => {
    return {
      creatorUid: createdBy === "Me" ? user?.uid : undefined,
      onlyFavorite: category?.includes("My favorites"),
      onlyCurrentWorkspace: createdBy === "Workspace",
      sortBy: sort.sortBy as SortOptions,
      tagIds: selectTags.map(i => i.id),
      searchTerm: search as string,
    }
  }, [category, selectTags, sort, search, user, createdBy])

  const debounceVariables = useDebounce(variables, 150)

  const {
    flattenData: mappedWorkflows,
    fetchNextPage,
    length,
    hasNextPage,
    isFetching,
    isLoading,
    isError,
  } = useExploreWorkflowsInfiniteQuery({
    variables: debounceVariables,
    enabled: !loading,
  })

  useEffect(() => {
    setIsLoadingInside(isLoading)
  }, [isLoading])

  const renderBody = () => {
    if (isLoading) return <div className="flex items-center justify-center w-full flex-1 text-gray-600"></div>

    if (isError) return <ErrorUI />

    if (!isLoading && (!mappedWorkflows || mappedWorkflows.length === 0)) return <GalleryNotFound text="No items" />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={classNames("relative")}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={true}
          threshold={800}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            width: "100%",
            position: "relative",
          }}
          loader={
            <div key="loader" className="flex items-center justify-center py-4">
              Loading...
            </div>
          }
        >
          <Masonry
            breakpointCols={{
              default: getExploreBreakpointCols(containerWidth),
            }}
            className="flex w-full gap-4"
            columnClassName="gap-4 flex flex-col"
            id="workflow-list"
          >
            {mappedWorkflows?.map(workflow => {
              return (
                <div key={workflow.id}>
                  <RoutedDialogLink
                    name="workflowDetailExplore"
                    type={"push"}
                    state={{
                      workflowId: workflow.id,
                      filters: {
                        historyPath: router.asPath,
                      },
                    }}
                  >
                    <WorkflowItem
                      hiddenCreator
                      hiddenMenu
                      isExplore
                      data={{
                        ...workflow,
                        status: RecipeTaskStatus.COMPLETED,
                        discoverableAt: "",
                      }}
                      containerWidth={containerWidth}
                      onSelect={() => {}}
                    />
                  </RoutedDialogLink>
                  <UserCreated
                    username={workflow.creator.username}
                    size="xs"
                    className="px-2"
                    name={workflow.creator.name}
                    picture={workflow.creator.picture}
                  />
                </div>
              )
            })}
          </Masonry>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <Fragment>
      {renderBody()}
      {length >= 100 && <LimitUserNotAuth />}
    </Fragment>
  )
}

export default memo(GalleryWorkflows)
