import { PickTypeClass, SortOptions } from "@/api/sdk"
import { useAuth } from "@/providers/authContext"
import { useExploreComfyUIInfiniteQuery } from "@/queries"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, memo, useEffect, useMemo } from "react"
import InfiniteScroll from "react-infinite-scroller"
import ComfyUIItemCard from "./ComfyUIItem"
import GalleryNotFound from "./GalleryNotFound"
import LimitUserNotAuth from "@/components/shared/LimitUserNotAuth"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"
import { useDebounce } from "@/hooks"
import ErrorUI from "@/components/ErrorUI"

interface GalleryComfyUIProps {
  containerWidth: number
  sort: {
    sortBy?: string
    sortOrder?: "ASC" | "DESC"
  }
  selectTags: PickTypeClass[]
  category: string[] | null
  setIsLoadingInside: (value: boolean) => void
  createdBy: string | null
}

const GalleryComfyUI = ({ category, sort, selectTags, setIsLoadingInside, createdBy }: GalleryComfyUIProps) => {
  const router = useCustomRouter()
  const { search } = router.query
  const { user, loading } = useAuth()

  const variables = useMemo(() => {
    return {
      creatorUid: createdBy === "Me" ? user?.uid : undefined,
      onlyFavorite: category?.includes("My favorites"),
      onlyCurrentWorkspace: createdBy === "Workspace",
      sortBy: sort.sortBy as SortOptions,
      tagIds: selectTags.map(i => i.id),
      searchTerm: search as string,
    }
  }, [category, selectTags, sort, search, user, createdBy])

  const debounceVariables = useDebounce(variables, 150)

  const {
    flattenData: mappedRecipes,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    length,
    isError,
  } = useExploreComfyUIInfiniteQuery({
    variables: debounceVariables,
    enabled: !loading,
  })

  useEffect(() => {
    setIsLoadingInside(isLoading)
  }, [isLoading])

  const renderBody = () => {
    if (isLoading) return <div className="flex items-center justify-center flex-1 w-full text-gray-600"></div>

    if (isError) return <ErrorUI />

    if (!mappedRecipes || mappedRecipes.length === 0) return <GalleryNotFound text="No items" />
    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        useWindow={true}
        threshold={800}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35, delay: 0.35 }}
          className={"relative"}
        >
          <GridBreakpointColsContainer>
            {mappedRecipes?.map(workflow => <ComfyUIItemCard key={workflow.id} workflow={workflow} />)}
          </GridBreakpointColsContainer>
        </motion.div>
      </InfiniteScroll>
    )
  }

  return (
    <Fragment>
      {renderBody()}
      {length >= 100 && <LimitUserNotAuth />}
    </Fragment>
  )
}

export default memo(GalleryComfyUI)
